import React from "react";

const WorkshopsHeader = () => {
	return (
		<div className="section-blog__wrapper__header">
			<h1 className="h1">Training</h1>
			<p>Workshops and training courses led by members of our expert team to upskill yours on both cutting-edge and well-established tech.</p>
		</div>
	);
};

export default WorkshopsHeader;
