import React from "react";
import Img from "gatsby-image";

// Sections
import WorkshopsHeader from "./partials/WorkshopsHeader";

// Images
import Image from "../elements/Image";

const PostSection = ({ data, config }) => {
	return (
		<section className="section-blog section-post">
			<div className="container">
				<div className="section-blog__wrapper">
					<WorkshopsHeader />
					<div className="section-blog__wrapper__content">
						<article>
							<div className="section-blog__wrapper__content--image">
								<Image src={data.frontmatter.image} alt={data.frontmatter.title} className="img-fluid" />
							</div>
							<div className="section-blog__wrapper__content__inner">
								<div className="section-blog__wrapper__content__author" />
								<h1>{data.frontmatter.title}</h1>
								<div className="section-blog__wrapper__content__inner--text" dangerouslySetInnerHTML={{ __html: data.html }}></div>
								<div className="section-blog__wrapper__content__inner--curriculum">
									<h2>What's covered</h2>
									<ul>{data.frontmatter.curriculum.map((item) => <li>{item}</li>)}</ul>
								</div>
							</div>
						</article>
					</div>
				</div>
			</div>
		</section>
	);
};

export default PostSection;
