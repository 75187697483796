import React from "react";
import { graphql } from "gatsby";

// Sections
import WorkshopPostSection from "../../components/sections/WorkshopPostSection";
import Cta from "../../components/sections/Cta";
import Layout from "../../layouts"

const Workshop = ({ data }) => {
	return (
		<Layout>
			<WorkshopPostSection data={data.workshop} config={data.site.config} />
			<Cta />
		</Layout>
	);
};

export default Workshop;

export const query = graphql`
  query WorkshopBySlug($slug: String!) {
    site {
      ...ConfigSiteTitle
      ...ConfigSiteDomain
      ...ConfigBlogBasePath
    }
    workshop: markdownRemark(
      fields: {
        slug: {
          eq: $slug
        }
      }
    ) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        image
        curriculum
        remote
        onsite
        length
        attendees
      }
    }
  }
`;
